<template>
    <div class="page">
        <a id="skip-to-content" href="#content-main">{{ $i18n.t('skipToContent') }}</a>
        <main class="page-container">
            <NuxtLoadingIndicator
                :throttle="200"
                :duration="2000"
                :color="colors.primary"
            />
            <DSNotifications />
            <LayoutCmsHeader
                :navigation="layoutData?.navigation"
                theme="white"
            >
                <template #left>
                    <nuxt-link
                        :to="$paths.getPath('stationLocator')"
                        class="link"
                    >
                        <DSIcon code="map" />
                        <span>{{ $i18n.t('additionalLinks.carwash') }}</span>
                    </nuxt-link>
                </template>
                <template #logo>
                    <nuxt-link class="logo-link" :to="$paths.getPath('landingPage')">
                        <img class="logo" src="/images/logo.svg" :alt="$t('logoAlt')" />
                    </nuxt-link>
                </template>
                <template #right>
                    <LayoutMiniCart :checkout-to="$paths.getPath('checkout')" />
                    <LayoutUserMenu />
                    <LayoutLanguage />
                </template>
                <template #mobile>
                    <nuxt-link
                        :to="$paths.getPath('stationLocator')"
                        class="link"
                    >
                        <DSIcon :aria-label="$i18n.t('additionalLinks.carwash')" code="map" size="xl" />
                    </nuxt-link>
                    <LayoutMiniCart :checkout-to="$paths.getPath('checkout')" />
                    <LayoutUserMenu />
                </template>
            </LayoutCmsHeader>
            <div id="content" class="content-container">
                <div
                    class="loading"
                    :class="{
                        'active': usePageStore().getLoading,
                    }"
                ></div>
                <NuxtPage
                    id="content-main"
                    class="page-content"
                    :transition="{
                        onBeforeEnter,
                    }"
                />
            </div>
            <DSModal />
        </main>
        <LayoutFooter
            :icons="layoutData?.icons"
            :items="layoutData?.footerLinks"
            :download="layoutData?.downloadLinks"
            :some="layoutData?.someLinks"
            :extras="layoutData?.extraLinks"
        />
    </div>
</template>
<script lang="js" setup>
import { useLayoutStore } from '~dsc/stores/cms/layout';
import { usePageStore } from '~dsc/stores/cms/page';
import { useCartStore } from '~dsc/stores/cms/cart';
import { useNuxtApp, provide, useI18n, onMounted, useAnalytics } from '#imports';
import { storeToRefs } from 'pinia';
import * as autopesuTokens from '~dsc/assets/scss/themes/st1-autopesu/_export.module.scss';

const { finalizePendingLocaleChange } = useI18n();
const { layoutData } = await storeToRefs(useLayoutStore());
const { $i18n, $paths } = useNuxtApp();
const { initGtm } = useAnalytics();
const stickyHeader = true;
const stickyMargin = stickyHeader ? '10.375rem' : '0';

const colors = {
    'primary': autopesuTokens.default['color-primary-500'],
};

initGtm();

const onBeforeEnter = async () => {
    await finalizePendingLocaleChange();
    usePageStore().present();
};

onMounted(async () => {
    await useCartStore().loadFromLocal();
});

provide('layout', {
    theme: 'white',
});

useI18n({
    useScope: 'global',
    messages: {
        'fi-FI': {
            logoAlt: 'St1 Autopesu',
            login: {
                link: 'Kirjaudu',
            },
            additionalLinks: {
                carwash: 'Autopesulat',
            },
            skipToContent: 'Hyppää sisältöön',
        },
        'en-EU': {
            login: {
                link: 'Login',
            },
            additionalLinks: {
                carwash: 'Carwashes',
            },
            skipToContent: 'Skip to content',
        },
    },
});
</script>
<style lang="scss">
body {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

header {
    z-index: 5;
}

.content-container {
    position: relative;
    overflow: hidden;

    @include respond-to('at least desktop') {
        padding-top: v-bind(stickyMargin);
    }
}

.page-container {
    width: 100%;
    overflow-x: hidden;
    min-height: calc(100vh - 4rem);

    > .notifications {
        position: fixed;
        height: calc(100vh - 20rem);
        width: 90vw;
        padding-block: 1rem;
        max-width: var(--page-max-width);
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        z-index: 6;
        pointer-events: none;

        .ds-notification {
            pointer-events: auto;
        }
    }
}

.page {
    display: grid;
    min-height: 100vh;
    max-width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(calc(100vh - v-bind(stickyMargin)), 1fr);

    @include respond-to('at most small desktop') {
        padding-top: 4rem;
        grid-template-rows: minmax(calc(100vh - 4rem), 1fr);
    }
}

.page-content {
    display: flex;
    flex-flow: row wrap;
    min-height: calc(100vh - v-bind(stickyMargin));

    @include respond-to('at most small desktop') {
        min-height: calc(100vh - 4rem);
    }
}
</style>
<style lang="scss" scoped>
.link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    letter-spacing: .02em;
}

.logo-link {
    display: inline-flex;
}

.logo {
    width: auto;
    max-height: 3rem;
    object-fit: contain;

    @include respond-to('at most small desktop') {
        max-height: 2.5rem;
    }
}

@include respond-to('at least desktop') {
    .icon.icon-map {
        margin-right: .5rem;
    }
}

#skip-to-content {
    position: absolute;

    &:focus {
        display: block;
        text-align: center;
        padding: 1em;
        width: 100%;
        background: white;
        z-index: 999;
    }
}
</style>
